import {
  Box,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from '@chakra-ui/react'

interface AssociateCaseModalIProps {
  isOpen: boolean
  onClose: () => void
}

export type FormInputsT = {
  incidentId: string
}

export const AssociateCaseModal = ({
  isOpen,
  onClose,
}: AssociateCaseModalIProps) => {
  return (
    <Modal
      isOpen={isOpen}
      motionPreset='slideInBottom'
      onClose={onClose}
      size='xl'
    >
      <ModalOverlay />
      <ModalContent bg='#fff'>
        <ModalHeader>
          <Box align-items='center' display='flex' m='20px auto'>
            <Box color='#353849' flexDirection='row' ml={2}>
              <Box fontSize='25px' fontWeight={700}>
                Associate an incident with a case
              </Box>
            </Box>
            <Spacer />
            <ModalCloseButton color='#000' opacity={0.4} position='initial' />
          </Box>
        </ModalHeader>
        <ModalBody pt={0} px={6} py={2}>
          {/* {TODO ENG-15332 New/Existing case association } */}

          <Tabs isFitted variant='primary'>
            <TabList>
              <Tab borderTopLeftRadius='0.5rem'>New Case</Tab>
              <Tab borderTopRightRadius='0.5rem'>Existing Case</Tab>
            </TabList>
            <TabPanels>
              <TabPanel>
                <Box> New Case stuff here</Box>
              </TabPanel>
              <TabPanel>
                <Box>Existing Case stuff here</Box>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
