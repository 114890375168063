import { useReportDownload } from '../download-report/hooks/useReportDownload'
import { FeatureFlag, useFeatureFlag } from '@/hooks/useFeatureFlags'
import { usePermissions } from '@/hooks/usePermissions'
import { HiOutlineDotsHorizontal, HiOutlineDownload } from 'react-icons/hi'

import {
  Button,
  Icon,
  Menu,
  MenuButton,
  MenuList,
  Tooltip,
  useDisclosure,
} from '@chakra-ui/react'

import { CaseManagement } from '@/components/icons'
import { MenuSelectItem } from '@/components/ui'
import { IncidentQuery } from '@/graphql/generated/operations'

import { AssociateCaseModal } from '../associate-case'

export interface AdditionalOptionsButtonIProps {
  incident: IncidentQuery['incident']
  isDownloadReportEnabled: boolean
}

export const AdditionalOptionsButton = ({
  incident,
  isDownloadReportEnabled,
}: AdditionalOptionsButtonIProps) => {
  const { downloadReport } = useReportDownload({ incident })
  const isCaseManagementEnabled = useFeatureFlag(FeatureFlag.caseManagement)
  const { shouldEnableCaseManagement } = usePermissions()
  const shouldShowAssociateIncident =
    isCaseManagementEnabled && shouldEnableCaseManagement
  const {
    isOpen: isAssociateCaseModalModalOpen,
    onOpen: onAssociateCaseModalModalOpen,
    onClose: onAssociateCaseModalModalClose,
  } = useDisclosure()
  return (
    <>
      <Menu closeOnBlur={true} gutter={0} isLazy={true} matchWidth={false}>
        <MenuButton pointerEvents='none'>
          <Button
            _active={{ bgColor: '#5f96f5', color: '#fff' }}
            _disabled={{
              opacity: 0.4,
              cursor: 'not-allowed',
              _hover: {
                bgColor: '#e3eefb',
              },
            }}
            _focus={{ outline: 0 }}
            _hover={{ bgColor: '#1b253f', color: '#5a6c9a' }}
            border='none'
            ms='5px'
            outline='none'
            pointerEvents='auto'
            variant='secondary'
          >
            <HiOutlineDotsHorizontal size={24} />
          </Button>
        </MenuButton>
        <MenuList p={0}>
          {shouldShowAssociateIncident && (
            <MenuSelectItem
              icon={<Icon as={CaseManagement} strokeColor='#1A202C' />}
              label='Associate Incident'
              onClick={onAssociateCaseModalModalOpen}
            />
          )}

          <Tooltip
            hasArrow
            isDisabled={isDownloadReportEnabled}
            label='Incident must be resolved to download report'
            placement='left'
            shouldWrapChildren
          >
            <MenuSelectItem
              icon={<HiOutlineDownload />}
              isDisabled={!isDownloadReportEnabled}
              label='Download Report'
              onClick={downloadReport}
              showDivider={false}
            />
          </Tooltip>
        </MenuList>
      </Menu>
      {isAssociateCaseModalModalOpen && (
        <AssociateCaseModal
          isOpen={isAssociateCaseModalModalOpen}
          onClose={onAssociateCaseModalModalClose}
        />
      )}
    </>
  )
}
